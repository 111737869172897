<template>
  <v-card>
    <v-card-title class="subtitle-1 lightgray py-2">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        label="Buscar"
        clearable
        solo
        flat
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        color="buttons"
        depressed
        dark
        @click="addCampo"
        v-if="vueAddVariavel"
      >
        Variável
        <v-icon right>add</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <template v-if="configs">
      <v-card-text class="py-4">
        <v-data-table
          :headers="headers"
          :items="configs"
          :search="search"
          :items-per-page="-1"
          :sort-by="['id']"
          class="data-tables "
          :class="{ 'data-tables__row-click': vueVariavel }"
          @click:row="goToCampo"
        >
          <template v-slot:item.actions="{ item }" v-if="vueDelVariavel">
            <v-btn icon depressed small @click.stop="delConfig(item.id)">
              <v-icon small>delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <Campo :dialog.sync="dialog" :item="config" :add="add" />
    </template>
    <Carregando v-else />
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ListaCampos",
  components: {
    Campo: () => import("./Campo"),
  },
  props: {},
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Campo",
          value: "campo",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Grupo",
          value: "grupo",
        },
        {
          text: "Categoria",
          value: "categoria",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      dialog: false,
      config: {},
      add: false,
    };
  },
  computed: {
    ...mapState("Tocsconfig", {
      configs: (state) => state.configs,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueVariavel() {
      return this.getAccess("vueVariavel", "visualizar");
    },
    vueDelVariavel() {
      return this.getAccess("vueVariavel", "deletar");
    },
    vueAddVariavel() {
      return this.getAccess("vueVariavel", "adicionar");
    },
  },
  methods: {
    ...mapActions("Tocsconfig", {
      getConfigs: "getConfigs",
      delConfig: "delConfig",
    }),
    goToCampo(item) {
      if (this.vueVariavel) {
        this.add = false;
        this.config = item;
        this.dialog = true;
      }
    },
    addCampo() {
      this.config = {};
      this.add = true;
      this.dialog = true;
    },
  },
  created() {},
  mounted() {
    this.$Progress.start();
    this.getConfigs()
      .then(() => this.$Progress.finish())
      .catch(() => this.$Progress.fail());
  },
};
</script>

<style lang="scss" scoped></style>
